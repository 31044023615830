import { CheckIcon, ClipboardIcon } from "@heroicons/react/outline";
import { Constants } from "@videosdk.live/react-sdk";
import React, { useState, useEffect } from "react";
import { createMeeting, getToken } from "../api";

import { meetingTypes } from "../utils/common";

export function MeetingDetailsScreen({
  onClickJoin,
  _handleOnCreateMeeting,
  participantName,
  setParticipantName,
  videoTrack,
  setVideoTrack,
  onClickStartMeeting,
  setMeetingMode,
  meetingMode,
  meetingType,
  setMeetingType,
}) {
  const [meetingId, setMeetingId] = useState("");
  const [meetingIdError, setMeetingIdError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [iscreateMeetingClicked, setIscreateMeetingClicked] = useState(false);
  const [isJoinMeetingClicked, setIsJoinMeetingClicked] = useState(true);
  const [premeetingObj, setPremeetingObj] = useState({});
  const [customerName, setCustomerName] = useState("");
  const [agentName, setAgentName] = useState("");
  const [isMeetingFound, setIsMeetingFound] = useState(false);

  const queryURL = new URLSearchParams(window.location.search);
  const key = queryURL.get("key") || "";
  const agent_key = queryURL.get("agent_key") || "";
  const statusParticipant = agent_key !== "" ? `agent` : `customer`;
  let reqParam = "";

  // Save key data to sessionStorage
  sessionStorage.setItem("key", key);

  const createRoomId = async () => {
    const token = await getToken();
    const _meetingId = await createMeeting({ token });
    setMeetingId(_meetingId);
  };

  const updateRoomId = () => {
    fetch(`${process.env.REACT_APP_CSSP_BASE_URL}/updateroomid?key=${key}`, {
      method: "POST",
      body: JSON.stringify({
        room_id: meetingId,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
  };

  useEffect(() => {
    if (key !== "") {
      reqParam = `?key=${key}`;
    } else {
      reqParam = null;
    }

    fetch(`${process.env.REACT_APP_CSSP_BASE_URL}/claimMedia?key=${key}`)
      .then((res) => {
        if (res.status === 404) {
          setIsMeetingFound(false);
        } else {
          setIsMeetingFound(true);
          return res.json();
        }
      })
      .then(
        (result) => {
          if (result) {
            setPremeetingObj(result);
            setMeetingId(result.room_id);
            setParticipantName(result.name);

            if (result.room_id === null) {
              createRoomId();
            }
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const selectType = [
    { label: "Meeting", value: meetingTypes.MEETING },
    { label: "Interactive Live Streaming", value: meetingTypes.ILS },
  ];

  return (
    <>
      {isMeetingFound ? (
        <>
          <div className="text-sm lg:text-base text-center text-center lg:mt-9">
            <p>
              Hello <label id="userName">{participantName}</label>
            </p>
            <p>
              Please record a video to serve as supporting evidence for the
              claim.
            </p>
          </div>
          <div
            className={`flex items-center flex-1 flex-col w-full md:p-[6px] sm:p-1 p-1.5`}
          >
            <>
              <input
                hidden
                value={participantName}
                onChange={(e) => setParticipantName(e.target.value)}
                placeholder="Enter your name"
                className="px-4 py-3 mt-5 bg-gray-650 rounded-xl text-white w-full text-center"
              />

              {/* <p className="text-xs text-white mt-1 text-center">
          Your name will help everyone identify you in the meeting.
        </p> */}
              {/* <button
          disabled={participantName.length < 3}
          className={`w-full ${
            participantName.length < 3 ? "bg-gray-650" : "bg-purple-350"
          }  text-white px-2 py-3 rounded-xl mt-3`}
          onClick={async (e) => {
            const meetingId = await _handleOnCreateMeeting();
            setMeetingId(meetingId);
            onClickStartMeeting();
            setParticipantName(participantName);
            console.log("participant = ", participantName);
          }}
        >
          Start Capture
        </button> */}

              <button
                disabled={
                  typeof participantName !== "undefined" &&
                  participantName.length < 3
                }
                className={`w-full md:w-1/2 ${
                  typeof participantName !== "undefined" &&
                  participantName.length < 3
                    ? "bg-gray-650"
                    : "bg-purple-350"
                }  text-white px-2 py-3 rounded-xl mt-5`}
                onClick={(e) => {
                  if (iscreateMeetingClicked) {
                    if (videoTrack) {
                      videoTrack.stop();
                      setVideoTrack(null);
                    }
                    onClickStartMeeting();
                  } else {
                    if (meetingId.match("\\w{4}\\-\\w{4}\\-\\w{4}")) {
                      onClickJoin(meetingId);
                      if (premeetingObj.room_id === null) {
                        updateRoomId();
                      }
                    } else setMeetingIdError(true);
                  }
                }}
              >
                Start a capture
              </button>
            </>
          </div>
        </>
      ) : (
        <div>
          <p className="  text-xl text-center">Data not found</p>
          <p className="  text-center">Please check your key.</p>
        </div>
      )}
    </>
  );
}
